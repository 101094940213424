<template>
    <div v-bind="$attrs" class="main-div">
        <h5 v-if="matrix_populated && !isSetupApp && !isDetailApp" class="ms-3">{{ props.ConsequenceArea.name }}</h5>
        <div class="position-relative">
            <table class="table-matrix" :class="matrixSize.class">
                <tbody v-show="matrix_populated">
                    <tr>
                        <td></td>
                        <td class="matrix-consequence-text" v-for="conseq in consequence_values" :key="conseq.Value">
                            <span v-if="ShowConsequenceText">{{ $t(conseq.Consequence) }}</span>
                            <br v-if="ShowConsequenceText">
                            <span>{{ conseq.Value }}</span>
                        </td>
                        <td :rowspan="maxFValue+1"> <!-- +1 because of "invisible" td -->
                            <div class="xLabel">
                                {{ $t("Frequence") }}
                            </div>
                        </td>
                    </tr>
                    
                    <tr v-for="(row, rowIndex) in matrix_cells" :key="rowIndex">
                        <td class="matrix-probability-text">
                            <span v-if="ShowFrequencyValue">{{ row[rowIndex].FValue }}</span>
                            <span v-if="ShowFrequencyValue && ShowFrequencyText"> - </span>
                            <span v-if="ShowFrequencyText">{{ $t(row[rowIndex].Frequency) }}</span>
                            <br>
                            <span v-if="ShowFrequencyPercent">({{ $t(row[rowIndex].FrequencyPercentRange) }})</span>
                        </td>
                        <td class="cell" :data-x-value="cell.FValue" :data-c-value="cell.CValue" v-for="(cell, cellIndex) in row" :key="cellIndex" :style="{ backgroundColor: cell.Color, color: cell.TextColor }">
                            <!-- Celldata for setup app's -->
                            <span 
                                title="This is a test" 
                                style="cursor: help; font-size:1rem;"
                                class="fw-bold" 
                                v-if="isSetupApp"
                            >
                                Test
                            </span>
                             <!-- 
                                * Celldata for ramsregister matrix 
                                - Using v-show and v-if together because MatrixCell component needs to be loaded for it to emit toggleSpinner 
                            -->
                            <MatrixCellData 
                                v-if="!props.isDetailApp"
                                v-show="!showSpinner"
                                :key="componentKey"
                                :RiskIDs="RiskIDs"
                                :ConsequenceArea_ID="ConsequenceArea.id"
                                :MatrixCValue="cell.CValue"
                                :MatrixFValue="cell.FValue"
                                :MaxCellValue="maxFValue"
                                :ButtonColor="cell.TextColor"
                                @dataCell="dropDownToggle"
                                @toggleSpinner="(toggleSpinner: boolean) => showSpinner = toggleSpinner"  
                            />
                            
                            <!-- 
                                * Celldata for rams detail app 
                                - Using v-show and v-if together because MatrixCell component needs to be loaded for it to emit toggleSpinner     
                            -->
                            <MatrixCellData 
                                v-if="props.isDetailApp"
                                v-show="!showSpinner"
                                :key="componentKey"
                                :RiskIDs="RiskIDs"
                                :ConsequenceArea_ID="ConsequenceArea.id"
                                :MatrixCValue="cell.CValue"
                                :MatrixFValue="cell.FValue"
                                :MaxCellValue="maxFValue"
                                :ButtonColor="cell.TextColor"
                                :DetailAppAssessments="props.DetailAppAssessments"
                                @dataCell="dropDownToggle"
                                @toggleSpinner="(toggleSpinner: boolean) => showSpinner = toggleSpinner"  
                            />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td :colspan="maxCValue"><div class="ramsCLabel">{{ $t('Consequence') }}</div></td>
                    </tr>
                </tbody>
            </table>

            <!-- Set current assessment dropdown showed when cell is clicked -->
            <div class="dropdown-menu position-absolute setCurrAssessment"  ref="dropElm" :style="{ top: dTop + 'px', left: dLeft + 'px' }">
                <h6 class="dropdown-header my-0" style="text-transform: inherit;">{{ $t('Frequence and consequence') }}</h6>
                <button class="dropdown-item" @click="setCurrentAssessment">{{ $t('Set current assessment') }}</button>
            </div>

            <!-- Spinner overlay -->
            <div class="spinner-overlay position-absolute d-flex justify-content-center align-items-center" v-if="showSpinner">
                <div class="spinner-border" style="width: 6rem; height: 6rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, onMounted, watch, onUnmounted, computed, inject } from 'vue';
    import { getOrCreateDataObject, deleteDataObject, type DataObjectModels } from 'o365-dataobject';
    import { API } from 'o365-modules';
    import { $t } from 'o365-utils';
    import MatrixCellData from 'rams.libraries.vue.components.matrix.MatrixCellData.vue';
    import type { MatrixSetupItem, ConsequenceValue, EmittedValues, MatrixSize } from 'rams.libraries.vue.components.matrix.MatrixInterfaces.ts';

    const props = defineProps({
        "RiskIDs": { type: Object, default: () => {} },
        "isSetupApp": { type: Boolean, default: false }, 
        "isDetailApp": { type: Boolean, default: false },
        "DetailAppAssessments": { // Contains current row of Consequence-assessment and Frequency-assessment from risk detail app
            type: Array,
            default: () => []
        },
        "ConsequenceArea": {
            type: Object, 
            default: { id: 10000, name: "RAM" }
        },

        // From toolbar-comp
        "ShowFrequencyValue": { type: Boolean, default: true }, 
        "ShowFrequencyText": { type: Boolean, default: false }, 
        "ShowFrequencyPercent": { type: Boolean, default: false }, 
        "ShowConsequenceText": Boolean
    });

    const matrixSize = inject<MatrixSize>('matrixSize')
    const editEnabled = inject<boolean>('editEnabled'); // IF added in edit mode matrix selection tooltip appear from right side
    const random_number: number = Math.floor(Math.random() * (1000000 - 10 + 1)) + 10; // Creates a random number which will be used on the datasource names (this is purely because the ds's cant be named the same) 
    const consequenceValWhereClause = computed<string>(() => {
        return `Consequence_ID = ${props.ConsequenceArea.id}`
    })
    let maxFValue: number = 0;
    let maxCValue: number = 0;
    let componentKey: number = 1;
    let consequence_values: ConsequenceValue[] = [];
    let matrix_setup_data: MatrixSetupItem[] = [];
    let matrix_cells: (MatrixSetupItem | null)[][] = [];
    const matrix_populated  = ref<boolean>(false); 
    const showSpinner       = ref<boolean>(false);
    
    const local_dsConsequencesValues = getOrCreateDataObject<DataObjectModels>({
        id: `local_dsConsequencesValues-${random_number}`,
        viewName: 'atbv_RAMS_ConsequencesValues', maxRecords: -1,
        whereClause: consequenceValWhereClause,
        loadRecents: false,
        disableLayouts: true,
        fields: [
            { name: "Name", type: "string"}, 
            { name: "Value", type: "int", sortOrder: "1", sortDirection: "asc"},
            { name: "Consequence_ID", type: "int"}
        ]
    });

    onMounted(async () => {
        await populateMatrixArraysAndDS();
    });

    onUnmounted(() => {
        deleteDataObject(local_dsConsequencesValues.id, local_dsConsequencesValues.appId);
    })

    const populateMatrixArraysAndDS = async () => {
        // Resetting arrays and variables
        matrix_setup_data.length = 0;
        matrix_cells.length = 0;
        matrix_populated.value = false;
        consequence_values.length = 0;

        // One time request for the ramsmatrix setup data
        const json_request = await API.request({
            requestInfo: '/api/data/aviw_RAMS_Matrix',
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                'viewName': 'aviw_RAMS_Matrix',
                'distinctRows': false,
                'skip': 0,
                'fields': [
                    { name: "Type", type: "string"}, 
                    { name: "FValue", type: "int"},
                    { name: "CValue", type: "int"},
                    { name: "Color", type: "string"},
                    { name: "Frequency", type: "string"},
                    { name: "TextColor", type: "string"},
                    { name: "CalculatedCriticality", type: "int"},
                    { name: "FrequencyPercentRange", type: "string"}
                ],
                'loadRecents': false,
                'masterDetailString': null,
                'filterString': null,
                'operation': 'retrieve'
            })
        });

        json_request.forEach(
            // Creating local array with values from json-request
            (matrix: MatrixSetupItem) => matrix_setup_data.push({
                Type:                   matrix.Type, 
                FValue:                 matrix.FValue, 
                CValue:                 matrix.CValue, 
                Color:                  matrix.Color, 
                Frequency:              matrix.Frequency,
                TextColor:              matrix.TextColor, 
                CalculatedCriticality:  matrix.CalculatedCriticality,
                FrequencyPercentRange:  matrix.FrequencyPercentRange
            })
        ) 

        await buildMatrix();
    }

    const buildMatrix = async () => {
        maxFValue = getMaxFValue();
        maxCValue = getMaxCValue();

        for (let x = maxFValue; x >= 1; x--) {
            const row: (MatrixSetupItem | null)[] = [];

            for (let c = 1; c <= maxCValue; c++) {
                const item = matrix_setup_data.find(
                    (matrixItem) => matrixItem.FValue === x && matrixItem.CValue === c
                );

                // Push either the found item or null if not found
                row.push(item || null);
            }

            matrix_cells.push(row);
        }

        await populateConsequenceValues();
    }

    const getMaxFValue = (): number => {
        return Math.max(...matrix_setup_data.map((item) => item.FValue));
    };

    const getMaxCValue = (): number => {
        return Math.max(...matrix_setup_data.map((item) => item.CValue));
    };
    
    const populateConsequenceValues = async () => { // This function is for setting the default-values to the consequence-"row". 1-6
        await local_dsConsequencesValues.load().then(() => {
            const data = local_dsConsequencesValues.data;

            if(data.length === maxCValue) {
                for(let i: number = 0; i < maxCValue; i++) {
                    consequence_values.push({
                        Value: data[i].Value,
                        Consequence: data[i].Name !== null ? data[i].Name : ""
                    });
                }
            } else {
                for(let i: number = 1; i <= maxCValue; ++i) {
                    consequence_values.push({ Value: i, Consequence: ""});
                }
            }
        });
        
        matrix_populated.value = true;
    }

    watch(() => props.RiskIDs, (newValue, oldValue) => {
        if(newValue.length !== oldValue.length) {
            forceRerender();
        }
    });
    
    const forceRerender = () => {
        componentKey += 1;
    };

    //Drop down positioning and functionality
    const dropElm       = ref(null);
    let newDropCValue   = ref<number>(0);
    let newDropFValue   = ref<number>(0);
    let dTop            = ref<number>(0);
    let dLeft           = ref<number>(0);
    
    function dropDownToggle(emittedValues: EmittedValues){
        const dropDowns = document.querySelectorAll('.setCurrAssessment');
        dropDowns.forEach(element => {
            element.classList?.remove('show');
        });

        if(emittedValues.MatrixCValue != newDropCValue.value || emittedValues.MatrixFValue != newDropFValue.value || !dropElm.value?.classList?.contains('show')){
            newDropCValue.value = emittedValues.MatrixCValue;
            newDropFValue.value = emittedValues.MatrixFValue;

            // LEFT SIDE
            if(!editEnabled.value){
                dTop.value = emittedValues.CellTopPosition + 30;
                dLeft.value = emittedValues.CellLeftPosition - 187;
            }
            // Right SIDE
            else{
                dTop.value = emittedValues.CellTopPosition + 30;
                dLeft.value = emittedValues.CellLeftPosition;

            }
            dropElm.value?.classList?.remove('show')
            dropElm.value?.classList?.add('show')
        }else{
            dropElm.value?.classList?.remove('show')
        }
    }

    // Hides the drop down when clicked outside the cell
    window.addEventListener("click", function(event: Event) {
        const target = event.target as HTMLInputElement; 
        if (!target.classList?.contains('boxCell') ) {
            dropElm.value?.classList?.remove('show')
        }
    });

    const setCurrentAssessment = () => {
        props.DetailAppAssessments[0].Value = newDropCValue.value; // Setting current consequence assessment value to the matrix's C cell value
        props.DetailAppAssessments[0].FrequencyValue = newDropFValue.value; // Setting current frequency assessment value to the matrix's F cell value
        props.DetailAppAssessments[2].save(); // -> dsConsAssessments.save()
        // props.DetailAppAssessments[1].save(); // -> dsFreqAssessments.save()
    }
</script>

<style scoped>
    /* Fade in of main matrix-element */
    .main-div {
        opacity: 0;
        animation: fadeInAnimation 0.5s ease-in-out forwards;
    }

    @keyframes fadeInAnimation {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Spinner overlay */
    .spinner-overlay {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
    }

    /* Common for all matrix sizes */
    .table-matrix {
        text-align: center;
    }

    .table-matrix td {
        border: 0.5px solid lightgray;
    }

    .table-matrix td.matrix-probability-text {
        font-size: 0.8em;
        border: none !important;
        text-align: left;
        padding-left: 6px;
        padding-right: 6px;
        white-space: nowrap;
    }

    .table-matrix td.matrix-consequence-text {
        vertical-align: top; 
        font-size: 0.8em;
        border: none !important;
    }

    .table-matrix tr td:first-child {
        text-align: left;
        border: none !important;
    }

    .table-matrix tr:last-child td,
    .table-matrix tr:first-child td {
        border: none !important;
    }

    .table-matrix td.cell:hover {
        -webkit-box-shadow: 2px 4px 22px 2px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 2px 4px 22px 2px rgba(0, 0, 0, 0.75);
        box-shadow: 2px 4px 22px 2px rgba(0, 0, 0, 0.75);
    }

    .xLabel {
        display: inline-table;
        writing-mode: tb-rl;
        font-size: 1rem;
        padding-left: 6px;
    }

    .ramsCLabel {
        display: inline-table;
        font-size: 1rem;
        padding-left: 6px;
    }

    /* Matrix - extra small */
    .table-matrix-xs td.cell {
        width: 25px;
        height: 25px;
        border: 0.5px solid lightgray;
        font-weight: bold;

        max-height: 25px;
        min-width: 25px;
    }

    /* Matrix - small*/
    .table-matrix-sm td.cell {
        width: 50px;
        height: 50px;
        border: 0.5px solid lightgray;
        font-weight: bold;

        max-height: 50px;
        min-width: 50px;
    }

    /* Matrix - medium */
    .table-matrix-md td.cell {
        width: 70px;
        height: 70px;
        border: 0.5px solid lightgray;
        font-weight: bold;

        max-height: 70px;
        min-width: 70px;

        overflow: auto;
    }

    /* Matrix - large */
    .table-matrix-lg td.cell {
        width: 100px;
        height: 100px;
        border: 0.5px solid lightgray;
        font-weight: bold;

        max-height: 100px;
        min-width: 100px; 
    }
</style>